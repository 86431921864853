import { authLegacyAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

/*
 * Send form for lost password
 */
export function useLostPassword() {
  async function lostPassword(email: string) {
    const body = new URLSearchParams({
      login: email,
    })

    const res = await useHttpFetch(authLegacyAPI.postLostPassword, {
      body,
    })

    return {
      ...res,
    }
  }

  return { lostPassword }
}
