<template>
  <NuxtLayout :name="layout">
    <RevContainer>
      <div class="grid-standard mb-32 text-center">
        <ExplanationModal :password-reset-reason="passwordResetReason" />
        <div class="md:col-span-4 lg:col-span-8 lg:col-start-3">
          <h1 class="heading-1 my-32">
            {{ i18n(translations.title) }}
          </h1>
          <p class="body-1">
            {{ i18n(translations.text) }}
          </p>
          <ResetPasswordForm />
        </div>
      </div>
    </RevContainer>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useHead, useRoute } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/auth/constants'

import ExplanationModal from '../components/ExplanationModal/ExplanationModal.vue'
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm.vue'

import translations from './ResetPassword.translations'

const i18n = useI18n()
const route = useRoute()
const passwordResetReason = ref('default')

const layout = computed(() => {
  return route.query.origin === 'auth' ? 'minimal' : 'default'
})

useHead({
  title: i18n(translations.metaTitle),
  meta: [
    {
      name: 'description',
      hid: 'description',
      content: i18n(translations.metaDescription),
    },
    {
      property: 'og:title',
      hid: 'og:title',
      content: i18n(translations.metaTitle),
    },
    {
      property: 'og:description',
      hid: 'og:description',
      content: i18n(translations.metaDescription),
    },
    {
      property: 'twitter:title',
      hid: 'twitter:title',
      content: i18n(translations.metaTitle),
    },
    {
      property: 'twitter:description',
      hid: 'twitter:description',
      content: i18n(translations.metaDescription),
    },
  ],
})

onMounted(() => {
  if (route.query.reason) {
    passwordResetReason.value = route.query.reason as string
    openModal(MODAL_NAMES.RESET_PASSWORD_EXPLANATION)
  }
})
</script>
