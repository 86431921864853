import { createHttpEndpoint } from '../../utils'

/**
 * LostPasswordView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#tag/legacy_auth/operation/post-bm-lost-password}
 */
export const postLostPassword = createHttpEndpoint<void>({
  method: 'POST',
  operationId: 'post-bm-lost-password',
  path: '/bm/lost-password',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
})
